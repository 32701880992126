import React from 'react';

import type { ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import type { CompanyPricingApi } from '@npm/data-access';

import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';

type DataType =
  CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_bids'][number];

export const LEVEL_COLUMNS: ExtendedColumnType<DataType>[] = [
  {
    title: 'Date',
    key: 'date',
    render: (_, { date }) =>
      formatDate(date, { dateFormat: DATE_FORMATS.MONTH_AND_YEAR }),
  },
  {
    title: 'PPS',
    key: 'pps',
    render: (
      _,
      { price_per_share, implied_valuation, discount_or_premium }
    ) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        impliedValuation={implied_valuation}
        change={discount_or_premium}
        multiplyChange={true}
      />
    ),
  },
];
