import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { type CompanyPricingApi } from '@npm/data-access';

import { sortArrayByDateField } from '../../../CompanyOverview.utils';
import {
  TableScrollIndicator,
  useScrollIndicatorClick,
} from '../../../components/TableScrollIndicator';

import { LEVEL_COLUMNS } from './Levels.columns';

import * as S from './Levels.styles';

type DataType =
  CompanyPricingApi.HistoricalPriceIndex['historical_prices']['aggregated_bids'][number];

const TABLE_MAX_HEIGHT = 240;

type Props = {
  title: string;
  tooltip: string;
  noDataTitle: string;
  items: DataType[];
  icon: React.ReactElement;
};

export const Levels = ({ title, tooltip, noDataTitle, items, icon }: Props) => {
  const theme = useTheme();
  const data = useMemo(() => sortArrayByDateField(items, 'desc'), [items]);
  const showScrollIndicator = data?.length > 4;
  const tableId = title.replace(' ', '-').toLowerCase();

  const { tableProps, scrollIndicatorProps } =
    useScrollIndicatorClick<DataType>(
      tableId,
      TABLE_MAX_HEIGHT,
      showScrollIndicator
    );

  const header = (
    <Margin left={'md'} top={'sm'} bottom={'sm'}>
      <Flex direction="row" gap={'xs'} align={'center'}>
        {icon && (
          <S.HeadingTooltipIconWrapper>{icon}</S.HeadingTooltipIconWrapper>
        )}
        <Heading variant="h2">
          {title}{' '}
          <Text colorVariant="tertiary" as="span">
            ({data.length})
          </Text>
        </Heading>

        {tooltip && (
          <Tooltip title={tooltip}>
            <S.HeadingTooltipIconWrapper>
              <Icon
                name="info-circle"
                size="sm"
                color={theme.color.info.typography.primary}
              />
            </S.HeadingTooltipIconWrapper>
          </Tooltip>
        )}
      </Flex>
    </Margin>
  );

  return (
    <CardBase
      noContentPadding={true}
      header={header}
      style={{ position: 'relative' }}
    >
      <Table<DataType>
        id={tableId}
        disablePagination={true}
        outlined={false}
        dataSource={data}
        columns={LEVEL_COLUMNS}
        noDataArea={<NoDataArea title={noDataTitle} />}
        {...tableProps}
      />
      {showScrollIndicator && (
        <TableScrollIndicator {...scrollIndicatorProps} />
      )}
    </CardBase>
  );
};
