import { useMemo } from 'react';

import type { WithDate } from '../../../CompanyOverview.types';
import {
  getFirstDayOfMonth,
  getMinimumDate,
} from '../../../CompanyOverview.utils';

export const useBidOfferHistoryChartDateLimits = (
  bidsData: WithDate[],
  asksData: WithDate[],
  valuationData: WithDate[]
) => {
  return useMemo(() => {
    const minDate = getMinimumDate(bidsData, asksData, valuationData);
    const maxDate = new Date();

    return [getFirstDayOfMonth(minDate), maxDate];
  }, [bidsData, asksData, valuationData]);
};
