import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { useIsMutating } from '@tanstack/react-query';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes/index';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';

import { BrokerageLayout } from '../components/Layout/BrokerageLayout';

import { routes } from './routes.brokerageWorkstation';

type Props = {
  enabled: boolean;
};

export const BrokerageRouter = ({ enabled }: Props) => {
  const { isEnabled } = useFeatureFlags();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  const isWorkstatingSwitchingInProgress =
    useIsMutating(['WorkstationCreate']) > 0;

  return (
    <Switch>
      {enabled && (
        <BrokerageLayout>
          {allRoutes.map(route => {
            return route.needLogin !== false ? (
              <ProtectedRoute
                key={route.path}
                path={route.path}
                component={route.component}
                permissionsChecks={route.permissionsChecks}
                isLoading={isWorkstatingSwitchingInProgress}
                exact
              />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact
              />
            );
          })}
        </BrokerageLayout>
      )}
      {isWorkstatingSwitchingInProgress && (
        <Route>
          <FullScreenLoading loadingText="Switching your workstation..." />
        </Route>
      )}
      <Route>
        <ErrorPath
          heading="Error"
          title="Sorry, the page you visited does not exist."
          status="404"
        />
      </Route>
    </Switch>
  );
};
