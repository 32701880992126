import styled from 'styled-components';

import { Text } from '@npm/core/ui/components/atoms/Typography';

export const Wrapper = styled.div`
  width: auto;
`;

export const ChildrenWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.general.layout.one};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm}px;

  & > * {
    flex-grow: 1;
  }
`;

export const Footer = styled(Text)`
  text-align: center;
`;
