/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrderItemIndex } from '../model';
/**
 * OrderItemApi - axios parameter creator
 * @export
 */
export const OrderItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list order items
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [accountId] 
         * @param {number} [eventId] 
         * @param {Array<string>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemIndex: async (page?: number, size?: number, accountId?: number, eventId?: number, state?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/order-items`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (state) {
                localVarQueryParameter['state'] = state.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download order items csv
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemsDownloadIndex: async (xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/order-items/download`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemApi - functional programming interface
 * @export
 */
export const OrderItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list order items
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [accountId] 
         * @param {number} [eventId] 
         * @param {Array<string>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemIndex(page?: number, size?: number, accountId?: number, eventId?: number, state?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemIndex(page, size, accountId, eventId, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download order items csv
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemsDownloadIndex(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemsDownloadIndex(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderItemApi - factory interface
 * @export
 */
export const OrderItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemApiFp(configuration)
    return {
        /**
         * 
         * @summary list order items
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [accountId] 
         * @param {number} [eventId] 
         * @param {Array<string>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemIndex(page?: number, size?: number, accountId?: number, eventId?: number, state?: Array<string>, options?: any): AxiosPromise<OrderItemIndex> {
            return localVarFp.orderItemIndex(page, size, accountId, eventId, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download order items csv
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemsDownloadIndex(xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderItemsDownloadIndex(xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orderItemIndex operation in OrderItemApi.
 * @export
 * @interface OrderItemApiOrderItemIndexRequest
 */
export interface OrderItemApiOrderItemIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemApiOrderItemIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof OrderItemApiOrderItemIndex
     */
    readonly size?: number

    /**
     * 
     * @type {number}
     * @memberof OrderItemApiOrderItemIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof OrderItemApiOrderItemIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof OrderItemApiOrderItemIndex
     */
    readonly state?: Array<string>
}

/**
 * Request parameters for orderItemsDownloadIndex operation in OrderItemApi.
 * @export
 * @interface OrderItemApiOrderItemsDownloadIndexRequest
 */
export interface OrderItemApiOrderItemsDownloadIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof OrderItemApiOrderItemsDownloadIndex
     */
    readonly xUserRoleId?: string
}

/**
 * OrderItemApi - object-oriented interface
 * @export
 * @class OrderItemApi
 * @extends {BaseAPI}
 */
export class OrderItemApi extends BaseAPI {
    /**
     * 
     * @summary list order items
     * @param {OrderItemApiOrderItemIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemApi
     */
    public orderItemIndex(requestParameters: OrderItemApiOrderItemIndexRequest = {}, options?: AxiosRequestConfig) {
        return OrderItemApiFp(this.configuration).orderItemIndex(requestParameters.page, requestParameters.size, requestParameters.accountId, requestParameters.eventId, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download order items csv
     * @param {OrderItemApiOrderItemsDownloadIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemApi
     */
    public orderItemsDownloadIndex(requestParameters: OrderItemApiOrderItemsDownloadIndexRequest = {}, options?: AxiosRequestConfig) {
        return OrderItemApiFp(this.configuration).orderItemsDownloadIndex(requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type OrderItemIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrderItemIndexErrorCodes = [
    401,
];

export type OrderItemsDownloadIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const OrderItemsDownloadIndexErrorCodes = [
    401,
];


