/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  InvestorsApi,
  InternalCompanyInvestorsIndexErrorCodes,
  InternalCompanyInvestorsIndexErrorTypes,
  InternalInvestorsIndexErrorCodes,
  InternalInvestorsIndexErrorTypes,
  InternalInvestorsCreateErrorCodes,
  InternalInvestorsShowErrorCodes,
  InternalInvestorsShowErrorTypes,
  InternalUpdateInvestorErrorCodes,
  InternalDeleteInvestorErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new InvestorsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalCompanyInvestorsIndexMethod = InvestorsApi['internalCompanyInvestorsIndex'];
export type InternalCompanyInvestorsIndexResponseType = MethodResult<InternalCompanyInvestorsIndexMethod>;

export const useInternalCompanyInvestorsIndex = (
  variables: Parameters<InternalCompanyInvestorsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >
) => {
  return useQuery<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >(
    (...args) => api.internalCompanyInvestorsIndex(...args),
    InternalCompanyInvestorsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyInvestorsIndex'],
        ...apiQueryConfig.InternalCompanyInvestorsIndex,
        ...config?.queryConfig
      },
    },
    'InternalCompanyInvestorsIndex',
  );
};

export const useInternalCompanyInvestorsIndexInfinite = (
  variables: Parameters<InternalCompanyInvestorsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >(
    (...args) => api.internalCompanyInvestorsIndex(...args),
    InternalCompanyInvestorsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyInvestorsIndex', 'InternalCompanyInvestorsIndexInfinite'],
        ...apiQueryConfig.InternalCompanyInvestorsIndex,
        ...config?.queryConfig,
      },
    },
    'InternalCompanyInvestorsIndex',
  );
};

export const useInternalCompanyInvestorsIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalCompanyInvestorsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompanyInvestorsIndexMethod,
    InternalCompanyInvestorsIndexErrorTypes
  >(
    (...args) => api.internalCompanyInvestorsIndex(...args),
    InternalCompanyInvestorsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompanyInvestorsIndex'],
        ...apiQueryConfig.InternalCompanyInvestorsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompanyInvestorsIndex',
  );
};


export type InternalInvestorsIndexMethod = InvestorsApi['internalInvestorsIndex'];
export type InternalInvestorsIndexResponseType = MethodResult<InternalInvestorsIndexMethod>;

export const useInternalInvestorsIndex = (
  variables: Parameters<InternalInvestorsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >
) => {
  return useQuery<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >(
    (...args) => api.internalInvestorsIndex(...args),
    InternalInvestorsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestorsIndex'],
        ...apiQueryConfig.InternalInvestorsIndex,
        ...config?.queryConfig
      },
    },
    'InternalInvestorsIndex',
  );
};

export const useInternalInvestorsIndexInfinite = (
  variables: Parameters<InternalInvestorsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >(
    (...args) => api.internalInvestorsIndex(...args),
    InternalInvestorsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestorsIndex', 'InternalInvestorsIndexInfinite'],
        ...apiQueryConfig.InternalInvestorsIndex,
        ...config?.queryConfig,
      },
    },
    'InternalInvestorsIndex',
  );
};

export const useInternalInvestorsIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalInvestorsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalInvestorsIndexMethod,
    InternalInvestorsIndexErrorTypes
  >(
    (...args) => api.internalInvestorsIndex(...args),
    InternalInvestorsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalInvestorsIndex'],
        ...apiQueryConfig.InternalInvestorsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalInvestorsIndex',
  );
};


export type InternalInvestorsCreateMethod = InvestorsApi['internalInvestorsCreate'];

export const useInternalInvestorsCreate = () => {
  return useMutation<InternalInvestorsCreateMethod>(
    (...args) => api.internalInvestorsCreate(...args),
    InternalInvestorsCreateErrorCodes,
    'InternalInvestorsCreate',
    '/api/internal/investments/{investment_id}/investors',
    'post'
  );
};

export type InternalInvestorsShowMethod = InvestorsApi['internalInvestorsShow'];
export type InternalInvestorsShowResponseType = MethodResult<InternalInvestorsShowMethod>;

export const useInternalInvestorsShow = (
  variables: Parameters<InternalInvestorsShowMethod>[0],
  config?: UseQueryConfig<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >
) => {
  return useQuery<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >(
    (...args) => api.internalInvestorsShow(...args),
    InternalInvestorsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestorsShow'],
        ...apiQueryConfig.InternalInvestorsShow,
        ...config?.queryConfig
      },
    },
    'InternalInvestorsShow',
  );
};

export const useInternalInvestorsShowInfinite = (
  variables: Parameters<InternalInvestorsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >(
    (...args) => api.internalInvestorsShow(...args),
    InternalInvestorsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestorsShow', 'InternalInvestorsShowInfinite'],
        ...apiQueryConfig.InternalInvestorsShow,
        ...config?.queryConfig,
      },
    },
    'InternalInvestorsShow',
  );
};

export const useInternalInvestorsShowLazy = (baseOptions?: {
  variables?: Parameters<InternalInvestorsShowMethod>[0],
  config?: UseQueryConfig<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalInvestorsShowMethod,
    InternalInvestorsShowErrorTypes
  >(
    (...args) => api.internalInvestorsShow(...args),
    InternalInvestorsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalInvestorsShow'],
        ...apiQueryConfig.InternalInvestorsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalInvestorsShow',
  );
};


export type InternalUpdateInvestorMethod = InvestorsApi['internalUpdateInvestor'];

export const useInternalUpdateInvestor = () => {
  return useMutation<InternalUpdateInvestorMethod>(
    (...args) => api.internalUpdateInvestor(...args),
    InternalUpdateInvestorErrorCodes,
    'InternalUpdateInvestor',
    '/api/internal/investors/{id}',
    'put'
  );
};

export type InternalDeleteInvestorMethod = InvestorsApi['internalDeleteInvestor'];

export const useInternalDeleteInvestor = () => {
  return useMutation<InternalDeleteInvestorMethod>(
    (...args) => api.internalDeleteInvestor(...args),
    InternalDeleteInvestorErrorCodes,
    'InternalDeleteInvestor',
    '/api/internal/investors/{id}',
    'delete'
  );
};
