import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { transparentise } from '@npm/core/ui/utils/style';
import { type CompanyPricingApi } from '@npm/data-access';

import type { CompanyOverviewChartDataSetEffect } from '../../../../TapeDPricing/TapeDPricing.types';
import { getOffersColor } from '../../../BidOfferHistory.utils';
import { convertOrderPriceToDataSet } from '../BidOfferHistoryChart.utils';

type HookOptions = {
  currentMinDate: Date;
  currentMaxDate: Date;
  series: 'PPS' | 'EST_VALUATION';
  effect?: CompanyOverviewChartDataSetEffect;
};

export const useOfferDataSet = (
  sortedAsksData: CompanyPricingApi.OrderPrice[],
  options: HookOptions
) => {
  const theme = useTheme();

  const { series, currentMinDate, currentMaxDate, effect } = options;

  return useMemo(() => {
    const data = convertOrderPriceToDataSet(
      sortedAsksData,
      series === 'PPS' ? 'price_per_share' : 'implied_valuation',
      'ask',
      currentMinDate,
      currentMaxDate
    );

    const borderColor = getOffersColor(theme);

    return {
      label: 'Offer Level',
      data: data.filter(Boolean),
      borderColor: function (context) {
        return effect === 'dim'
          ? transparentise(borderColor, 0.3)
          : borderColor;
      },
      borderWidth: effect === 'highlight' ? 3 : 1,
      segment: {
        borderWidth:
          effect === 'highlight'
            ? 3
            : ctx => {
                // Highlight the hovered line
                return ctx?.p0?.raw?.raw === ctx?.chart?.hoveredRaw?.raw ||
                  ctx?.p1?.raw?.raw === ctx?.chart?.hoveredRaw?.raw
                  ? 3
                  : 1;
              },
      },
    };
  }, [theme, sortedAsksData, series, currentMinDate, currentMaxDate, effect]);
};
