import React from 'react';

import { Card } from '@npm/core/ui/components/molecules/Card';
import { type VenusApi } from '@npm/data-access';

import { type SectionProps } from '../CompanyOverview.types';

import { CorporateProfile } from './CorporateProfile';
import { DescriptionWithBaseInfo } from './DescriptionWithBaseInfo';
import { Investors } from './Investors';

import * as S from './General.styles';

export const General = ({
  issuerEntity,
  companyProfile,
  isLoading,
}: SectionProps & {
  companyProfile: VenusApi.CompanyProfile;
}) => {
  return (
    <S.Container>
      <S.Col>
        <Card>
          <DescriptionWithBaseInfo
            companyProfile={companyProfile}
            issuerEntity={issuerEntity}
            isLoading={isLoading}
          />
        </Card>
        <Card>
          <Investors
            companyProfile={companyProfile}
            isLoading={isLoading}
            collapseAfter={50}
          />
        </Card>
      </S.Col>
      <S.Col $sideColumn>
        <Card>
          <CorporateProfile
            companyProfile={companyProfile}
            isLoading={isLoading}
          />
        </Card>
      </S.Col>
    </S.Container>
  );
};
