import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTheme } from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useSMHeaderHeight } from '@npm/core/ui/hooks/useSMHeaderHeight';
import {
  type IssuerEntityAggregate,
  CompanyPricingApi,
  VenusApi,
} from '@npm/data-access';

import {
  CompanyDataSectionKeys,
  SectionQueryKey,
} from '../CompanyOverview.utils';
import { CompanyDataSection } from '../components/CompanyDataSection';
import { CompanyDataSectionLoader } from '../components/CompanyDataSectionLoader/CompanyDataSectionLoader';
import { CoveredSection } from '../components/CoveredSection';
import { MutualFundMarks } from '../MutualFundMarks/MutualFundMarks';
import { PrimaryRounds } from '../PrimaryRounds';
import { CompanyOverviewSummary } from '../Summary';
import { TapeD } from '../TapeD';
import {
  BidOfferHistory,
  PUBLIC_INFO_TOOLTIP,
  SectionHeading,
  Valuations409A,
} from '..';

import { useCompanyOverviewMaximumValues } from './TapeDPricing.hooks';

type Props = {
  issuerEntity?: IssuerEntityAggregate;
  isLoadingIssuerEntity?: boolean;
  companyProfile?: VenusApi.CompanyProfile;
};

export const TapeDPricing = ({
  issuerEntity,
  isLoadingIssuerEntity,
  companyProfile,
}: Props) => {
  const [sectionQueryParam, setSectionQueryParam] = useQueryParam(
    SectionQueryKey,
    StringParam
  );
  const handleError = useErrorHandler();
  const headerHeight = useSMHeaderHeight();
  const theme = useTheme();

  const venusCompanyId = issuerEntity?.venus_id;
  const issuerEntityId = issuerEntity?.id;

  const { isMobile } = useBreakpoints();

  const { data: tapeDData, isFetching: isLoadingTapeD } =
    CompanyPricingApi.useCompanyIntrinsicValues(
      {
        companyId: venusCompanyId?.toString(),
        size: PAGE_SIZE_HARD_LIMIT,
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: err => {
          handleError(err);
        },
      }
    );

  const { data: bidOfferData, isFetching: isLoadingBidOffer } =
    CompanyPricingApi.useHistoricalPricesIndex(
      {
        companyId: venusCompanyId?.toString(),
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: err => {
          if (err?.status === -1 && err?.originalStatus === 404) return; // ignore
          handleError(err);
        },
      }
    );

  const { data: primaryRoundsData, isFetching: isLoadingPrimaryRounds } =
    VenusApi.useCompanyPrimaryRounds(
      {
        companyId: venusCompanyId,
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: err => {
          if (err?.status === 404) return; // ignore
          handleError(err);
        },
      }
    );

  const { data: valuations409Data, isFetching: isLoadingValuations409 } =
    VenusApi.useCompany409As(
      {
        companyId: venusCompanyId?.toString(),
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
      }
    );

  const { data: mutualFundData, isFetching: isLoadingMutualFund } =
    CompanyPricingApi.useMTMs(
      {
        companyId: venusCompanyId?.toString(),
        size: PAGE_SIZE_HARD_LIMIT,
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: err => {
          if (err?.status === 404) return; // ignore
          handleError(err);
        },
      }
    );

  const { data: movingAverageData, isFetching: isLoadingMovingAverage } =
    CompanyPricingApi.useMovingAveragePrices(
      {
        companyId: venusCompanyId?.toString(),
        months: 24,
      },
      {
        queryConfig: { enabled: venusCompanyId != null },
        onError: err => {
          if (err?.status === 404) return; // ignore
          handleError(err);
        },
      }
    );

  const yAxisMaxValues = useCompanyOverviewMaximumValues({
    isLoading:
      isLoadingIssuerEntity ||
      isLoadingTapeD ||
      isLoadingBidOffer ||
      isLoadingPrimaryRounds ||
      isLoadingValuations409 ||
      isLoadingMovingAverage ||
      isLoadingMutualFund,
    tapeDData,
    bidOfferData,
    primaryRoundsData,
    valuations409Data,
    movingAverageData,
    mtmData: mutualFundData,
  });

  const isLoadingData =
    isLoadingIssuerEntity ||
    isLoadingTapeD ||
    isLoadingBidOffer ||
    isLoadingPrimaryRounds ||
    isLoadingValuations409 ||
    isLoadingMovingAverage;

  const tapeDHasData = !!tapeDData?.intrinsic_values?.length;

  const bidOfferHasSomeData =
    !!bidOfferData?.historical_prices?.aggregated_bids?.length ||
    !!bidOfferData?.historical_prices?.aggregated_asks?.length;

  const primaryRoundsHasData = !!primaryRoundsData?.primary_rounds?.length;

  const valuations409HasData = !!valuations409Data?.['409as']?.length;

  const movingAverageHasData = !!movingAverageData?.prices?.length;

  const mutualFundSectionHasData =
    movingAverageHasData || !!mutualFundData?.mark_to_markets?.length;

  const summarySectionHasData =
    tapeDHasData ||
    bidOfferHasSomeData ||
    valuations409HasData ||
    movingAverageHasData;

  // scroll to a section if query param is present
  useEffect(() => {
    if (sectionQueryParam && !isLoadingData) {
      const targetElement = document?.querySelector(`#${sectionQueryParam}`);

      if (targetElement) {
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offset = headerHeight + theme.spacing.md;
        const offsetPosition = elementPosition - offset;

        document.body.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });

        setSectionQueryParam(undefined, 'replaceIn');
      }
    }
  }, [sectionQueryParam, isLoadingData]);

  if (isLoadingData) {
    return (
      <Flex direction="column" gap={isMobile ? 'xl' : 'xxl'}>
        <CompanyDataSectionLoader />
        <CompanyDataSectionLoader />
        <CompanyDataSectionLoader />
        <CompanyDataSectionLoader />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="xxl">
      <CompanyDataSection
        sectionKey={CompanyDataSectionKeys.summary}
        title={'Summary'}
        tooltip={
          'Provides an overview of the pricing factors that are available for the time period shown.'
        }
        hasData={summarySectionHasData}
      >
        <CompanyOverviewSummary
          isCovered={companyProfile?.covered}
          tapeDData={tapeDData}
          bidOfferData={bidOfferData}
          primaryRoundsData={primaryRoundsData}
          valuations409Data={valuations409Data}
          movingAverageData={movingAverageData}
          yAxisMaxValues={yAxisMaxValues}
        />
      </CompanyDataSection>

      <CompanyDataSection
        sectionKey={CompanyDataSectionKeys.tape_d_price}
        title={'Tape D™ Price'}
        tooltip={
          "NPM's evaluated price per share range, updated on a daily basis, based on the pricing factors that are shown on this page and weighted by reliability and recency."
        }
        hasData={tapeDHasData}
      >
        <TapeD data={tapeDData} yAxisMaxValues={yAxisMaxValues} />
      </CompanyDataSection>

      <CompanyDataSection
        sectionKey={CompanyDataSectionKeys.bid_offer_history}
        title="Bid & Offer History"
        tooltip={
          "Average monthly bid and offer prices, based on indications received through NPM's platform and data contribution network."
        }
        hasData={bidOfferHasSomeData}
      >
        <BidOfferHistory
          data={bidOfferData}
          isCovered={companyProfile?.covered}
          yAxisMaxValues={yAxisMaxValues}
        />
      </CompanyDataSection>

      <CoveredSection
        sectionKey={CompanyDataSectionKeys.primary_rounds}
        heading={
          <SectionHeading
            title="Primary Rounds"
            tooltip={
              'Financings where the private company has raised money through the issuance of new shares or through resale of shares through a tender offer.'
            }
          />
        }
        renderContent={() => (
          <PrimaryRounds
            data={primaryRoundsData}
            venusCompanyId={venusCompanyId}
            issuerEntityId={issuerEntityId}
            yAxisMaxValues={yAxisMaxValues}
          />
        )}
        hasData={primaryRoundsHasData}
        issuerEntity={issuerEntity}
        companyProfile={companyProfile}
      />

      <CoveredSection
        sectionKey={CompanyDataSectionKeys.valuations409a}
        heading={
          <SectionHeading
            title="409A Valuations"
            tooltip={PUBLIC_INFO_TOOLTIP}
          />
        }
        renderContent={() => (
          <Valuations409A
            data={valuations409Data}
            venusCompanyId={venusCompanyId}
            issuerEntityId={issuerEntityId}
            yAxisMaxValues={yAxisMaxValues}
          />
        )}
        hasData={!!valuations409Data?.['409as']?.length}
        issuerEntity={issuerEntity}
        companyProfile={companyProfile}
      />

      <CompanyDataSection
        sectionKey={CompanyDataSectionKeys.mutual_fund_mark}
        title="Mutual Fund Marks"
        tooltip={
          "Price per share (PPS) and implied valuation of private company shares, as disclosed by '40 Act funds in their SEC filings."
        }
        hasData={mutualFundSectionHasData}
      >
        <MutualFundMarks
          mtmData={mutualFundData}
          movingAverageData={movingAverageData}
        />
      </CompanyDataSection>
    </Flex>
  );
};
