import { format } from 'date-fns';

import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';

import {
  type CompanyDataSectionKey,
  type WithDate,
} from './CompanyOverview.types';

export const CompanyDataSectionKeys = {
  summary: 'summary',
  tape_d_price: 'tape_d_price',
  bid_offer_history: 'bid_offer_history',
  mutual_fund_mark: 'mutual_fund_mark',
  financing_activity: 'financing_activity',
  primary_rounds: 'primary_rounds',
  valuations409a: 'valuations409a',
  cap_table: 'cap_table',
  mark_to_market: 'mark_to_market',
  tape_d_pricing: 'tape_d_pricing',
} as const;

export const SectionQueryKey = 'section';

export const getQueryParamForSection = (section: CompanyDataSectionKey) => {
  return `${SectionQueryKey}=${section}`;
};

export const sortArrayByDateField = <T extends WithDate>(
  data: T[] | undefined,
  order: 'asc' | 'desc' = 'asc'
): T[] => {
  if (data == null) {
    return [];
  }

  return data.sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.date) < new Date(b.date) ? -1 : 1;
    }
    return new Date(a.date) > new Date(b.date) ? -1 : 1;
  });
};

export const getMonthlyXLabels = (
  currentMinDate: Date,
  currentMaxDate: Date
) => {
  const result = [];

  const currentMonth = getFirstDayOfMonth(currentMinDate);
  for (let i = 0; currentMonth <= currentMaxDate; i++) {
    result.push(parseDateToString(currentMonth, DATE_FORMATS.MONTH_AND_YEAR));
    currentMonth.setMonth(currentMonth.getMonth() + 1);
  }

  return result;
};

export const getMaximumDate = (...dataArrays: WithDate[][]) => {
  return new Date(
    Math.max(
      ...dataArrays
        .map(dataArray => dataArray[dataArray.length - 1]?.date)
        .filter(Boolean)
        .map(d => new Date(d).getTime())
    )
  );
};

export const getMinimumDate = (...dataArrays: WithDate[][]) => {
  return new Date(
    Math.min(
      ...dataArrays
        .map(dataArray => dataArray[0]?.date)
        .filter(Boolean)
        .map(d => new Date(d).getTime())
    )
  );
};

export const getFirstDayOfMonth = (value: string | Date) => {
  if (value instanceof Date) {
    const result = new Date(value);
    result.setUTCDate(1);
  }

  const date = new Date(value);
  date.setUTCDate(1);

  return date;
};

export const formatMonthAndYearForChartLabel = (value: string) => {
  const date = new Date(value);
  // return an array of 2 items to place year under month
  return format(date, DATE_FORMATS.MONTH_AND_YEAR).split(' ');
};

type Point<T> = { x: string; y: number; raw: T };

export const sliceDataset = <T>(
  fullDataset: Point<T>[],
  rangeStart: Date,
  rangeEnd: Date,
  maxDate: Date
) => {
  const extendedDataset = [...fullDataset];

  if (extendedDataset.length) {
    // Add a point at the end of the dataset to make the line extend to the end of the chart
    extendedDataset.push({
      x: maxDate.toISOString(),
      y: extendedDataset[extendedDataset.length - 1].y,
      raw: extendedDataset[extendedDataset.length - 1].raw,
    });
  }

  const slice: Point<T>[] = [];
  let lastPointBeforeMin: Point<T> = null;
  let lastPointBeforeMax: Point<T> = null;
  let firstPointAfterMax: Point<T> = null;

  for (const point of extendedDataset) {
    if (new Date(point.x) < rangeStart) {
      lastPointBeforeMin = point;
    } else if (new Date(point.x) > rangeEnd) {
      if (!lastPointBeforeMax) {
        lastPointBeforeMax = slice[slice.length - 1] ?? lastPointBeforeMin;
      }
      if (!firstPointAfterMax) {
        firstPointAfterMax = point;
      }
    } else {
      slice.push(point);
    }
  }

  if (lastPointBeforeMin) {
    slice.unshift({
      ...lastPointBeforeMin,
      x: rangeStart.toISOString(),
      raw: lastPointBeforeMin.raw,
    });
  }

  if (lastPointBeforeMax) {
    slice.push({
      ...lastPointBeforeMax,
      x: rangeEnd.toISOString(),
      raw: firstPointAfterMax.raw,
    });
  }

  return slice;
};
