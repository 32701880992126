/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { ImportFetchSourcesRequestContract } from '../model';
/**
 * OcfImportsApi - axios parameter creator
 * @export
 */
export const OcfImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary imports ocf files from s3 files
         * @param {string} issuerId 
         * @param {ImportFetchSourcesRequestContract} importFetchSourcesRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAndImportOcfFiles: async (issuerId: string, importFetchSourcesRequestContract: ImportFetchSourcesRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('fetchAndImportOcfFiles', 'issuerId', issuerId)
            // verify required parameter 'importFetchSourcesRequestContract' is not null or undefined
            assertParamExists('fetchAndImportOcfFiles', 'importFetchSourcesRequestContract', importFetchSourcesRequestContract)
            const localVarPath = changeUrl(`/api/issuers/{issuer_id}/imports/fetch_from_source`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importFetchSourcesRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary imports ocf files from attached files
         * @param {string} issuerId 
         * @param {string} issuerId2 
         * @param {string} [xWorkstation] 
         * @param {Array<string>} [includeField] 
         * @param {string} [startFromTxId] 
         * @param {File} [manifest] 
         * @param {File} [stockClasses] 
         * @param {File} [stockLegendTemplates] 
         * @param {File} [stockPlans] 
         * @param {File} [vestingTerms] 
         * @param {File} [stakeholders] 
         * @param {File} [valuations] 
         * @param {File} [transactions] 
         * @param {File} [marks] 
         * @param {File} [zip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOcfFiles: async (issuerId: string, issuerId2: string, xWorkstation?: string, includeField?: Array<string>, startFromTxId?: string, manifest?: File, stockClasses?: File, stockLegendTemplates?: File, stockPlans?: File, vestingTerms?: File, stakeholders?: File, valuations?: File, transactions?: File, marks?: File, zip?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('importOcfFiles', 'issuerId', issuerId)
            // verify required parameter 'issuerId2' is not null or undefined
            assertParamExists('importOcfFiles', 'issuerId2', issuerId2)
            const localVarPath = changeUrl(`/api/issuers/{issuer_id}/imports`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (issuerId2 !== undefined) { 
                localVarFormParams.append('issuer_id', issuerId2 as any);
            }
    
            if (startFromTxId !== undefined) { 
                localVarFormParams.append('start_from_tx_id', startFromTxId as any);
            }
    
            if (manifest !== undefined) { 
                localVarFormParams.append('manifest', manifest as any);
            }
    
            if (stockClasses !== undefined) { 
                localVarFormParams.append('stock_classes', stockClasses as any);
            }
    
            if (stockLegendTemplates !== undefined) { 
                localVarFormParams.append('stock_legend_templates', stockLegendTemplates as any);
            }
    
            if (stockPlans !== undefined) { 
                localVarFormParams.append('stock_plans', stockPlans as any);
            }
    
            if (vestingTerms !== undefined) { 
                localVarFormParams.append('vesting_terms', vestingTerms as any);
            }
    
            if (stakeholders !== undefined) { 
                localVarFormParams.append('stakeholders', stakeholders as any);
            }
    
            if (valuations !== undefined) { 
                localVarFormParams.append('valuations', valuations as any);
            }
    
            if (transactions !== undefined) { 
                localVarFormParams.append('transactions', transactions as any);
            }
    
            if (marks !== undefined) { 
                localVarFormParams.append('marks', marks as any);
            }
    
            if (zip !== undefined) { 
                localVarFormParams.append('zip', zip as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OcfImportsApi - functional programming interface
 * @export
 */
export const OcfImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OcfImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary imports ocf files from s3 files
         * @param {string} issuerId 
         * @param {ImportFetchSourcesRequestContract} importFetchSourcesRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAndImportOcfFiles(issuerId: string, importFetchSourcesRequestContract: ImportFetchSourcesRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAndImportOcfFiles(issuerId, importFetchSourcesRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary imports ocf files from attached files
         * @param {string} issuerId 
         * @param {string} issuerId2 
         * @param {string} [xWorkstation] 
         * @param {Array<string>} [includeField] 
         * @param {string} [startFromTxId] 
         * @param {File} [manifest] 
         * @param {File} [stockClasses] 
         * @param {File} [stockLegendTemplates] 
         * @param {File} [stockPlans] 
         * @param {File} [vestingTerms] 
         * @param {File} [stakeholders] 
         * @param {File} [valuations] 
         * @param {File} [transactions] 
         * @param {File} [marks] 
         * @param {File} [zip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importOcfFiles(issuerId: string, issuerId2: string, xWorkstation?: string, includeField?: Array<string>, startFromTxId?: string, manifest?: File, stockClasses?: File, stockLegendTemplates?: File, stockPlans?: File, vestingTerms?: File, stakeholders?: File, valuations?: File, transactions?: File, marks?: File, zip?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importOcfFiles(issuerId, issuerId2, xWorkstation, includeField, startFromTxId, manifest, stockClasses, stockLegendTemplates, stockPlans, vestingTerms, stakeholders, valuations, transactions, marks, zip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OcfImportsApi - factory interface
 * @export
 */
export const OcfImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OcfImportsApiFp(configuration)
    return {
        /**
         * 
         * @summary imports ocf files from s3 files
         * @param {string} issuerId 
         * @param {ImportFetchSourcesRequestContract} importFetchSourcesRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAndImportOcfFiles(issuerId: string, importFetchSourcesRequestContract: ImportFetchSourcesRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.fetchAndImportOcfFiles(issuerId, importFetchSourcesRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary imports ocf files from attached files
         * @param {string} issuerId 
         * @param {string} issuerId2 
         * @param {string} [xWorkstation] 
         * @param {Array<string>} [includeField] 
         * @param {string} [startFromTxId] 
         * @param {File} [manifest] 
         * @param {File} [stockClasses] 
         * @param {File} [stockLegendTemplates] 
         * @param {File} [stockPlans] 
         * @param {File} [vestingTerms] 
         * @param {File} [stakeholders] 
         * @param {File} [valuations] 
         * @param {File} [transactions] 
         * @param {File} [marks] 
         * @param {File} [zip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOcfFiles(issuerId: string, issuerId2: string, xWorkstation?: string, includeField?: Array<string>, startFromTxId?: string, manifest?: File, stockClasses?: File, stockLegendTemplates?: File, stockPlans?: File, vestingTerms?: File, stakeholders?: File, valuations?: File, transactions?: File, marks?: File, zip?: File, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.importOcfFiles(issuerId, issuerId2, xWorkstation, includeField, startFromTxId, manifest, stockClasses, stockLegendTemplates, stockPlans, vestingTerms, stakeholders, valuations, transactions, marks, zip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fetchAndImportOcfFiles operation in OcfImportsApi.
 * @export
 * @interface OcfImportsApiFetchAndImportOcfFilesRequest
 */
export interface OcfImportsApiFetchAndImportOcfFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiFetchAndImportOcfFiles
     */
    readonly issuerId: string

    /**
     * 
     * @type {ImportFetchSourcesRequestContract}
     * @memberof OcfImportsApiFetchAndImportOcfFiles
     */
    readonly importFetchSourcesRequestContract: ImportFetchSourcesRequestContract

    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiFetchAndImportOcfFiles
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for importOcfFiles operation in OcfImportsApi.
 * @export
 * @interface OcfImportsApiImportOcfFilesRequest
 */
export interface OcfImportsApiImportOcfFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly issuerId2: string

    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly includeField?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly startFromTxId?: string

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly manifest?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly stockClasses?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly stockLegendTemplates?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly stockPlans?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly vestingTerms?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly stakeholders?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly valuations?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly transactions?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly marks?: File

    /**
     * 
     * @type {File}
     * @memberof OcfImportsApiImportOcfFiles
     */
    readonly zip?: File
}

/**
 * OcfImportsApi - object-oriented interface
 * @export
 * @class OcfImportsApi
 * @extends {BaseAPI}
 */
export class OcfImportsApi extends BaseAPI {
    /**
     * 
     * @summary imports ocf files from s3 files
     * @param {OcfImportsApiFetchAndImportOcfFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfImportsApi
     */
    public fetchAndImportOcfFiles(requestParameters: OcfImportsApiFetchAndImportOcfFilesRequest, options?: AxiosRequestConfig) {
        return OcfImportsApiFp(this.configuration).fetchAndImportOcfFiles(requestParameters.issuerId, requestParameters.importFetchSourcesRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary imports ocf files from attached files
     * @param {OcfImportsApiImportOcfFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcfImportsApi
     */
    public importOcfFiles(requestParameters: OcfImportsApiImportOcfFilesRequest, options?: AxiosRequestConfig) {
        return OcfImportsApiFp(this.configuration).importOcfFiles(requestParameters.issuerId, requestParameters.issuerId2, requestParameters.xWorkstation, requestParameters.includeField, requestParameters.startFromTxId, requestParameters.manifest, requestParameters.stockClasses, requestParameters.stockLegendTemplates, requestParameters.stockPlans, requestParameters.vestingTerms, requestParameters.stakeholders, requestParameters.valuations, requestParameters.transactions, requestParameters.marks, requestParameters.zip, options).then((request) => request(this.axios, this.basePath));
    }
}

export type FetchAndImportOcfFilesErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FetchAndImportOcfFilesErrorCodes = [
];

export type ImportOcfFilesErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const ImportOcfFilesErrorCodes = [
];


