/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  JobsApi,
  JobShowErrorCodes,
  JobShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new JobsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type JobShowMethod = JobsApi['jobShow'];
export type JobShowResponseType = MethodResult<JobShowMethod>;

export const useJobShow = (
  variables: Parameters<JobShowMethod>[0],
  config?: UseQueryConfig<
    JobShowMethod,
    JobShowErrorTypes
  >
) => {
  return useQuery<
    JobShowMethod,
    JobShowErrorTypes
  >(
    (...args) => api.jobShow(...args),
    JobShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['JobShow'],
        ...apiQueryConfig.JobShow,
        ...config?.queryConfig
      },
    },
    'JobShow',
  );
};

export const useJobShowInfinite = (
  variables: Parameters<JobShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    JobShowMethod,
    JobShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    JobShowMethod,
    JobShowErrorTypes
  >(
    (...args) => api.jobShow(...args),
    JobShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['JobShow', 'JobShowInfinite'],
        ...apiQueryConfig.JobShow,
        ...config?.queryConfig,
      },
    },
    'JobShow',
  );
};

export const useJobShowLazy = (baseOptions?: {
  variables?: Parameters<JobShowMethod>[0],
  config?: UseQueryConfig<
    JobShowMethod,
    JobShowErrorTypes
  >
}) => {
  return useLazyQuery<
    JobShowMethod,
    JobShowErrorTypes
  >(
    (...args) => api.jobShow(...args),
    JobShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['JobShow'],
        ...apiQueryConfig.JobShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'JobShow',
  );
};

