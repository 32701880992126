import React from 'react';
import { useTheme } from 'styled-components';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';

import { ChartLegendItem } from '../../../../components/ChartLegendItem';
import { PointLineIcon } from '../../../../components/PointLineIcon';
import { getPrimaryRoundsColor } from '../../../../PrimaryRounds/PrimaryRounds.utils';
import { getBidsColor, getOffersColor } from '../../../BidOfferHistory.utils';

export const BidOfferHistoryChartLegend = () => {
  const theme = useTheme();

  return (
    <Flex gap="md" align="center" justify="center">
      <ChartLegendItem
        icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
        label={'Bid Level'}
      />

      <ChartLegendItem
        icon={<Icon name="segment" size="xs" color={getOffersColor(theme)} />}
        label={'Offer Level'}
      />
      <ChartLegendItem
        icon={<PointLineIcon color={getPrimaryRoundsColor(theme)} />}
        label={'Primary Round'}
      />
    </Flex>
  );
};
