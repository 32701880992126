import { formatQuantity } from '@npm/core/ui/utils/formatters';
import { CbATSPool } from '@npm/data-access';

export const getMinimumNotionalValue = (
  atsPool: keyof typeof CbATSPool.items
) => (atsPool === CbATSPool.items.internal ? 25000 : 100000);

export const getMinimumNotionalValueErrorMessage = (
  atsPool: keyof typeof CbATSPool.items
) =>
  `Order must be greater than or equal to $${formatQuantity(
    getMinimumNotionalValue(atsPool)
  )} minimum notional value`;
