import { type XY } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.types';
import { type CompanyPricingApi } from '@npm/data-access';

import { getFirstDayOfMonth } from '../../../CompanyOverview.utils';

import { type BidOfferPrimaryRoundDataPoint } from './BidOfferHistoryChart.types';

export const BID_OFFER_CHART_HTML_ID = 'bid_offer_chart';

// Mathematical function to calculate distance between point and line (represented by two points)
export const getDistanceOfThePointFromTheLine = (
  point: XY,
  linePointA: XY,
  linePointB: XY
) => {
  const A = point.x - linePointA.x;
  const B = point.y - linePointA.y;
  const C = linePointB.x - linePointA.x;
  const D = linePointB.y - linePointA.y;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) param = dot / len_sq;

  let xx, yy;

  if (param < 0) {
    xx = linePointA.x;
    yy = linePointA.y;
  } else if (param > 1) {
    xx = linePointB.x;
    yy = linePointB.y;
  } else {
    xx = linePointA.x + param * C;
    yy = linePointA.y + param * D;
  }

  const dx = point.x - xx;
  const dy = point.y - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

export const getBidOffersChartTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(
    `#${BID_OFFER_CHART_HTML_ID}`
  ) as HTMLElement;
};

// pre-set dimensions for the tooltip
const TOOLTIP_RECT = {
  width: 250,
  height: 150,
};

const VERTICAL_OFFSET = 8;

export const showBidOfferHistoryChartTooltip = (
  context: {
    canvas: HTMLCanvasElement;
  },
  data: unknown | undefined,
  onSelectedPointChange: (data: unknown | undefined) => void,
  segment: {
    p1: XY;
    p2: XY;
  }
) => {
  const { canvas } = context;

  const tooltipEl = getBidOffersChartTooltip(canvas);

  const chartRect = canvas.getBoundingClientRect();

  // position tooltip relative to the point
  let x = Math.round(
    (segment.p1.x + segment.p2.x) / 2 - TOOLTIP_RECT.width / 2
  );
  let y = segment.p1.y + VERTICAL_OFFSET;

  const x1 = x + TOOLTIP_RECT.width;
  let y1 = y + TOOLTIP_RECT.height;

  // if tooltip is outside the chart (x-axis), reposition it
  if (x1 > chartRect.width) {
    x = x - (x1 - chartRect.width);
  }

  // if the current point, where the tooltip is shown, is in the bottom half of the chart, show the tooltip above the point instead of below
  if (y > chartRect.height / 2) {
    y = y - TOOLTIP_RECT.height - VERTICAL_OFFSET * 2;
    y1 = y + TOOLTIP_RECT.height;
  }

  // if tooltip is outside the chart (y-axis), reposition it
  if (y1 > chartRect.height) {
    y = y - (y1 - chartRect.height);
  }

  onSelectedPointChange(data);

  tooltipEl.style.opacity = '95%';
  tooltipEl.style.display = 'block';
  tooltipEl.style.position = 'absolute';

  tooltipEl.style.left = 0 + 'px';
  tooltipEl.style.top = y + 'px';

  tooltipEl.style.width = TOOLTIP_RECT.width + 'px';
  tooltipEl.style.height = TOOLTIP_RECT.height + 'px';

  tooltipEl.style.transform = `translateX(${x}px)`;
  tooltipEl.style.transition = 'all .1s ease';
};

export const convertOrderPriceToDataSet = (
  orderPrices: CompanyPricingApi.OrderPrice[],
  dataPoint: 'price_per_share' | 'implied_valuation',
  type: 'bid' | 'ask',
  currentMinDate: Date,
  currentMaxDate: Date
) => {
  if (!orderPrices?.length) {
    return [];
  }

  const filteredDate = orderPrices?.filter(orderPrice => {
    return (
      new Date(orderPrice.date) >= currentMinDate &&
      new Date(orderPrice.date) < currentMaxDate
    );
  });

  const data = [];
  filteredDate.forEach(orderPrice => {
    const nextMonth = getFirstDayOfMonth(orderPrice.date);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    data.push({
      x: getFirstDayOfMonth(orderPrice.date),
      y: orderPrice[dataPoint],
      raw: {
        type,
        data: orderPrice,
      },
    });
    data.push({
      x: nextMonth,
      y: orderPrice[dataPoint],
      raw: {
        type,
        data: orderPrice,
      },
    });

    // add null data to separate/disconnect the lines in the chart dataset
    data.push({
      x: null,
      y: null,
    });
  });

  return data;
};

export const convertPrimaryPriceToDataSet = (
  primaryRounds: CompanyPricingApi.PrimaryRoundPrice[],
  dataPoint: 'price_per_share' | 'valuation'
) => {
  if (!primaryRounds?.length) {
    return [];
  }

  const result: BidOfferPrimaryRoundDataPoint[] = primaryRounds.map(
    primaryRound => ({
      x: primaryRound.date,
      y: primaryRound[dataPoint],
      raw: {
        type: 'round' as const,
        data: primaryRound,
      },
    })
  );

  return result;
};
