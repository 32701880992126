import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { transparentise } from '@npm/core/ui/utils/style';
import { type CompanyPricingApi } from '@npm/data-access';

import type { CompanyOverviewChartDataSetEffect } from '../../../../TapeDPricing/TapeDPricing.types';
import { getBidsColor } from '../../../BidOfferHistory.utils';
import { convertOrderPriceToDataSet } from '../BidOfferHistoryChart.utils';

type HookOptions = {
  currentMinDate: Date;
  currentMaxDate: Date;
  series: 'PPS' | 'EST_VALUATION';
  effect?: CompanyOverviewChartDataSetEffect;
};

export const useBidDataSet = (
  sortedBidsData: CompanyPricingApi.OrderPrice[],

  options: HookOptions
) => {
  const theme = useTheme();

  const { series, currentMinDate, currentMaxDate, effect } = options;

  return useMemo(() => {
    const data = convertOrderPriceToDataSet(
      sortedBidsData,
      series === 'PPS' ? 'price_per_share' : 'implied_valuation',
      'bid',
      currentMinDate,
      currentMaxDate
    );

    const borderColor = getBidsColor(theme);

    return {
      label: 'Bid Level',
      data: data.filter(Boolean),
      borderColor: function (context) {
        return effect === 'dim'
          ? transparentise(borderColor, 0.3)
          : borderColor;
      },
      borderWidth: effect === 'highlight' ? 3 : 1,
      segment: {
        borderWidth: ctx => {
          // Highlight the hovered line
          return effect === 'highlight' ||
            ctx?.p0?.raw?.raw === ctx?.chart?.hoveredRaw?.raw ||
            ctx?.p1?.raw?.raw === ctx?.chart?.hoveredRaw?.raw
            ? 3
            : 1;
        },
      },
    };
  }, [theme, sortedBidsData, series, currentMinDate, currentMaxDate, effect]);
};
