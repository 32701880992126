/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CapTableCompanyMetadata } from '../model';
// @ts-ignore
import { Company } from '../model';
// @ts-ignore
import { CompanyImpliedValues } from '../model';
// @ts-ignore
import { CompanyIndex } from '../model';
// @ts-ignore
import { CompanyLatestRound } from '../model';
// @ts-ignore
import { CompanyProfile } from '../model';
// @ts-ignore
import { CompanyWaterfallLink } from '../model';
// @ts-ignore
import { FinancialActivityIndex } from '../model';
// @ts-ignore
import { InternalCompanyCreateRequestContract } from '../model';
// @ts-ignore
import { InternalCompanyIndex } from '../model';
// @ts-ignore
import { InternalCompanyPreDelete } from '../model';
// @ts-ignore
import { InternalCompanyShow } from '../model';
// @ts-ignore
import { InternalCompanyUpdateRequestContract } from '../model';
// @ts-ignore
import { InternalInvestmentPreDelete } from '../model';
// @ts-ignore
import { InternalStockSplitRequestContract } from '../model';
// @ts-ignore
import { PublicCompanyShow } from '../model';
// @ts-ignore
import { SecondMarketCompanyShow } from '../model';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get filing dates for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFilingDates: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesFilingDates', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/filing_dates`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all companies that meet the specific filters
         * @param {string} [sectors] 
         * @param {number} [minLatestInvestmentSize] 
         * @param {number} [maxLatestInvestmentSize] 
         * @param {string} [minLatestInvestmentDate] 
         * @param {string} [maxLatestInvestmentDate] 
         * @param {number} [minTotalFunding] 
         * @param {number} [maxTotalFunding] 
         * @param {number} [minLatestValuation] 
         * @param {number} [maxLatestValuation] 
         * @param {string} [coveredOnGt] 
         * @param {boolean} [covered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesIndex: async (sectors?: string, minLatestInvestmentSize?: number, maxLatestInvestmentSize?: number, minLatestInvestmentDate?: string, maxLatestInvestmentDate?: string, minTotalFunding?: number, maxTotalFunding?: number, minLatestValuation?: number, maxLatestValuation?: number, coveredOnGt?: string, covered?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/companies`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectors !== undefined) {
                localVarQueryParameter['sectors'] = sectors;
            }

            if (minLatestInvestmentSize !== undefined) {
                localVarQueryParameter['min_latest_investment_size'] = minLatestInvestmentSize;
            }

            if (maxLatestInvestmentSize !== undefined) {
                localVarQueryParameter['max_latest_investment_size'] = maxLatestInvestmentSize;
            }

            if (minLatestInvestmentDate !== undefined) {
                localVarQueryParameter['min_latest_investment_date'] = minLatestInvestmentDate;
            }

            if (maxLatestInvestmentDate !== undefined) {
                localVarQueryParameter['max_latest_investment_date'] = maxLatestInvestmentDate;
            }

            if (minTotalFunding !== undefined) {
                localVarQueryParameter['min_total_funding'] = minTotalFunding;
            }

            if (maxTotalFunding !== undefined) {
                localVarQueryParameter['max_total_funding'] = maxTotalFunding;
            }

            if (minLatestValuation !== undefined) {
                localVarQueryParameter['min_latest_valuation'] = minLatestValuation;
            }

            if (maxLatestValuation !== undefined) {
                localVarQueryParameter['max_latest_valuation'] = maxLatestValuation;
            }

            if (coveredOnGt !== undefined) {
                localVarQueryParameter['covered_on_gt'] = coveredOnGt;
            }

            if (covered !== undefined) {
                localVarQueryParameter['covered'] = covered;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get latest PPS(price per share) and Valuation details for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesLatestStockClassDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesLatestStockClassDetails', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/latest_stock_class_details`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates data_requested flag on record
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesRequestData: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesRequestData', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/request_data`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesShow: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesShow', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cap table information for a company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCapTableMetadata: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyCapTableMetadata', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/cap_table_metadata`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns financial activity
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFinancialActivity: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyFinancialActivity', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/financial_activity`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns implied values
         * @param {string} id 
         * @param {number} [valuation] 
         * @param {number} [pricePerShare] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyImpliedValues: async (id: string, valuation?: number, pricePerShare?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyImpliedValues', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/implied_price_data`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (valuation !== undefined) {
                localVarQueryParameter['valuation'] = valuation;
            }

            if (pricePerShare !== undefined) {
                localVarQueryParameter['price_per_share'] = pricePerShare;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get basic information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyProfile: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyProfile', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/profile`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns link to Waterfall file
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyWaterfallLink: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyWaterfallLink', 'id', id)
            const localVarPath = changeUrl(`/api/companies/{id}/waterfall_link`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a company
         * @param {InternalCompanyCreateRequestContract} internalCompanyCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesCreate: async (internalCompanyCreateRequestContract: InternalCompanyCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCompanyCreateRequestContract' is not null or undefined
            assertParamExists('internalCompaniesCreate', 'internalCompanyCreateRequestContract', internalCompanyCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/companies`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a list of companies
         * @param {boolean} [dataRequested] 
         * @param {boolean} [covered] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesIndex: async (dataRequested?: boolean, covered?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/companies`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataRequested !== undefined) {
                localVarQueryParameter['data_requested'] = dataRequested;
            }

            if (covered !== undefined) {
                localVarQueryParameter['covered'] = covered;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete associated records for a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesReset: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalCompaniesReset', 'id', id)
            const localVarPath = changeUrl(`/api/internal/companies/{id}/reset`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalCompaniesShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary apply stock split
         * @param {number} id 
         * @param {InternalStockSplitRequestContract} internalStockSplitRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyApplyStockSplit: async (id: number, internalStockSplitRequestContract: InternalStockSplitRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalCompanyApplyStockSplit', 'id', id)
            // verify required parameter 'internalStockSplitRequestContract' is not null or undefined
            assertParamExists('internalCompanyApplyStockSplit', 'internalStockSplitRequestContract', internalStockSplitRequestContract)
            const localVarPath = changeUrl(`/api/internal/companies/{id}/apply_stock_split`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalStockSplitRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyPreDelete: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalCompanyPreDelete', 'id', id)
            const localVarPath = changeUrl(`/api/internal/companies/{id}/pre_delete`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteCompany: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteCompany', 'id', id)
            const localVarPath = changeUrl(`/api/internal/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestmentPreDelete: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalInvestmentPreDelete', 'id', id)
            const localVarPath = changeUrl(`/api/internal/investments/{id}/pre_delete`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyUpdateRequestContract} [internalCompanyUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCompany: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyUpdateRequestContract?: InternalCompanyUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCompany', 'id', id)
            const localVarPath = changeUrl(`/api/internal/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCompanyUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary bulk update firmographics
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadFirmographics: async (file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('internalUploadFirmographics', 'file', file)
            const localVarPath = changeUrl(`/api/internal/companies/upload_firmographics`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary bulk update Sacra Data
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadSacraData: async (file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('internalUploadSacraData', 'file', file)
            const localVarPath = changeUrl(`/api/internal/companies/upload_sacra_data`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCompaniesShow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicCompaniesShow', 'id', id)
            const localVarPath = changeUrl(`/api/public/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get company for SecondMarket
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondMarketCompanyShow: async (id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secondMarketCompanyShow', 'id', id)
            const localVarPath = changeUrl(`/api/second_market/companies/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get filing dates for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesFilingDates(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesFilingDates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all companies that meet the specific filters
         * @param {string} [sectors] 
         * @param {number} [minLatestInvestmentSize] 
         * @param {number} [maxLatestInvestmentSize] 
         * @param {string} [minLatestInvestmentDate] 
         * @param {string} [maxLatestInvestmentDate] 
         * @param {number} [minTotalFunding] 
         * @param {number} [maxTotalFunding] 
         * @param {number} [minLatestValuation] 
         * @param {number} [maxLatestValuation] 
         * @param {string} [coveredOnGt] 
         * @param {boolean} [covered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesIndex(sectors?: string, minLatestInvestmentSize?: number, maxLatestInvestmentSize?: number, minLatestInvestmentDate?: string, maxLatestInvestmentDate?: string, minTotalFunding?: number, maxTotalFunding?: number, minLatestValuation?: number, maxLatestValuation?: number, coveredOnGt?: string, covered?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesIndex(sectors, minLatestInvestmentSize, maxLatestInvestmentSize, minLatestInvestmentDate, maxLatestInvestmentDate, minTotalFunding, maxTotalFunding, minLatestValuation, maxLatestValuation, coveredOnGt, covered, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get latest PPS(price per share) and Valuation details for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesLatestStockClassDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLatestRound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesLatestStockClassDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates data_requested flag on record
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesRequestData(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesRequestData(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesShow(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get cap table information for a company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCapTableMetadata(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapTableCompanyMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyCapTableMetadata(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns financial activity
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyFinancialActivity(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialActivityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyFinancialActivity(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns implied values
         * @param {string} id 
         * @param {number} [valuation] 
         * @param {number} [pricePerShare] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyImpliedValues(id: string, valuation?: number, pricePerShare?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyImpliedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyImpliedValues(id, valuation, pricePerShare, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get basic information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyProfile(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyProfile(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns link to Waterfall file
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyWaterfallLink(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyWaterfallLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyWaterfallLink(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a company
         * @param {InternalCompanyCreateRequestContract} internalCompanyCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompaniesCreate(internalCompanyCreateRequestContract: InternalCompanyCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompaniesCreate(internalCompanyCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a list of companies
         * @param {boolean} [dataRequested] 
         * @param {boolean} [covered] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompaniesIndex(dataRequested?: boolean, covered?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompaniesIndex(dataRequested, covered, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete associated records for a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompaniesReset(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompaniesReset(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompaniesShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompaniesShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary apply stock split
         * @param {number} id 
         * @param {InternalStockSplitRequestContract} internalStockSplitRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyApplyStockSplit(id: number, internalStockSplitRequestContract: InternalStockSplitRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyApplyStockSplit(id, internalStockSplitRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCompanyPreDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyPreDelete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCompanyPreDelete(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteCompany(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteCompany(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalInvestmentPreDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalInvestmentPreDelete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalInvestmentPreDelete(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyUpdateRequestContract} [internalCompanyUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCompany(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyUpdateRequestContract?: InternalCompanyUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCompany(id, xNPMAuthorization, xSubscriptionPlan, internalCompanyUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary bulk update firmographics
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUploadFirmographics(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUploadFirmographics(file, xNPMAuthorization, xSubscriptionPlan, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary bulk update Sacra Data
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUploadSacraData(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUploadSacraData(file, xNPMAuthorization, xSubscriptionPlan, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCompaniesShow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCompaniesShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get company for SecondMarket
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondMarketCompanyShow(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondMarketCompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondMarketCompanyShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary get filing dates for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFilingDates(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companiesFilingDates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all companies that meet the specific filters
         * @param {string} [sectors] 
         * @param {number} [minLatestInvestmentSize] 
         * @param {number} [maxLatestInvestmentSize] 
         * @param {string} [minLatestInvestmentDate] 
         * @param {string} [maxLatestInvestmentDate] 
         * @param {number} [minTotalFunding] 
         * @param {number} [maxTotalFunding] 
         * @param {number} [minLatestValuation] 
         * @param {number} [maxLatestValuation] 
         * @param {string} [coveredOnGt] 
         * @param {boolean} [covered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesIndex(sectors?: string, minLatestInvestmentSize?: number, maxLatestInvestmentSize?: number, minLatestInvestmentDate?: string, maxLatestInvestmentDate?: string, minTotalFunding?: number, maxTotalFunding?: number, minLatestValuation?: number, maxLatestValuation?: number, coveredOnGt?: string, covered?: boolean, options?: any): AxiosPromise<CompanyIndex> {
            return localVarFp.companiesIndex(sectors, minLatestInvestmentSize, maxLatestInvestmentSize, minLatestInvestmentDate, maxLatestInvestmentDate, minTotalFunding, maxTotalFunding, minLatestValuation, maxLatestValuation, coveredOnGt, covered, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get latest PPS(price per share) and Valuation details for a specific company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesLatestStockClassDetails(id: string, options?: any): AxiosPromise<CompanyLatestRound> {
            return localVarFp.companiesLatestStockClassDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates data_requested flag on record
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesRequestData(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companiesRequestData(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesShow(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companiesShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get cap table information for a company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCapTableMetadata(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<CapTableCompanyMetadata> {
            return localVarFp.companyCapTableMetadata(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns financial activity
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFinancialActivity(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<FinancialActivityIndex> {
            return localVarFp.companyFinancialActivity(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns implied values
         * @param {string} id 
         * @param {number} [valuation] 
         * @param {number} [pricePerShare] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyImpliedValues(id: string, valuation?: number, pricePerShare?: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<CompanyImpliedValues> {
            return localVarFp.companyImpliedValues(id, valuation, pricePerShare, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get basic information for a specific company
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyProfile(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<CompanyProfile> {
            return localVarFp.companyProfile(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns link to Waterfall file
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyWaterfallLink(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<CompanyWaterfallLink> {
            return localVarFp.companyWaterfallLink(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a company
         * @param {InternalCompanyCreateRequestContract} internalCompanyCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesCreate(internalCompanyCreateRequestContract: InternalCompanyCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyShow> {
            return localVarFp.internalCompaniesCreate(internalCompanyCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a list of companies
         * @param {boolean} [dataRequested] 
         * @param {boolean} [covered] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesIndex(dataRequested?: boolean, covered?: boolean, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyIndex> {
            return localVarFp.internalCompaniesIndex(dataRequested, covered, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete associated records for a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesReset(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyShow> {
            return localVarFp.internalCompaniesReset(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompaniesShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyShow> {
            return localVarFp.internalCompaniesShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary apply stock split
         * @param {number} id 
         * @param {InternalStockSplitRequestContract} internalStockSplitRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyApplyStockSplit(id: number, internalStockSplitRequestContract: InternalStockSplitRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyShow> {
            return localVarFp.internalCompanyApplyStockSplit(id, internalStockSplitRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompanyPreDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalCompanyPreDelete> {
            return localVarFp.internalCompanyPreDelete(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteCompany(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteCompany(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request associated records for a company before deletion
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalInvestmentPreDelete(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalInvestmentPreDelete> {
            return localVarFp.internalInvestmentPreDelete(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a company
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalCompanyUpdateRequestContract} [internalCompanyUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCompany(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalCompanyUpdateRequestContract?: InternalCompanyUpdateRequestContract, options?: any): AxiosPromise<InternalCompanyShow> {
            return localVarFp.internalUpdateCompany(id, xNPMAuthorization, xSubscriptionPlan, internalCompanyUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary bulk update firmographics
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadFirmographics(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.internalUploadFirmographics(file, xNPMAuthorization, xSubscriptionPlan, includeField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary bulk update Sacra Data
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadSacraData(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.internalUploadSacraData(file, xNPMAuthorization, xSubscriptionPlan, includeField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a specific company
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCompaniesShow(id: number, options?: any): AxiosPromise<PublicCompanyShow> {
            return localVarFp.publicCompaniesShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get company for SecondMarket
         * @param {string} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondMarketCompanyShow(id: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<SecondMarketCompanyShow> {
            return localVarFp.secondMarketCompanyShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companiesFilingDates operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompaniesFilingDatesRequest
 */
export interface CompaniesApiCompaniesFilingDatesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesFilingDates
     */
    readonly id: string
}

/**
 * Request parameters for companiesIndex operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompaniesIndexRequest
 */
export interface CompaniesApiCompaniesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly sectors?: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly minLatestInvestmentSize?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly maxLatestInvestmentSize?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly minLatestInvestmentDate?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly maxLatestInvestmentDate?: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly minTotalFunding?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly maxTotalFunding?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly minLatestValuation?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly maxLatestValuation?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly coveredOnGt?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiCompaniesIndex
     */
    readonly covered?: boolean
}

/**
 * Request parameters for companiesLatestStockClassDetails operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompaniesLatestStockClassDetailsRequest
 */
export interface CompaniesApiCompaniesLatestStockClassDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesLatestStockClassDetails
     */
    readonly id: string
}

/**
 * Request parameters for companiesRequestData operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompaniesRequestDataRequest
 */
export interface CompaniesApiCompaniesRequestDataRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesRequestData
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesRequestData
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesRequestData
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companiesShow operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompaniesShowRequest
 */
export interface CompaniesApiCompaniesShowRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompaniesShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companyCapTableMetadata operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompanyCapTableMetadataRequest
 */
export interface CompaniesApiCompanyCapTableMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyCapTableMetadata
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyCapTableMetadata
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyCapTableMetadata
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companyFinancialActivity operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompanyFinancialActivityRequest
 */
export interface CompaniesApiCompanyFinancialActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyFinancialActivity
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyFinancialActivity
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyFinancialActivity
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companyImpliedValues operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompanyImpliedValuesRequest
 */
export interface CompaniesApiCompanyImpliedValuesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyImpliedValues
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompanyImpliedValues
     */
    readonly valuation?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesApiCompanyImpliedValues
     */
    readonly pricePerShare?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyImpliedValues
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyImpliedValues
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companyProfile operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompanyProfileRequest
 */
export interface CompaniesApiCompanyProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyProfile
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyProfile
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyProfile
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for companyWaterfallLink operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCompanyWaterfallLinkRequest
 */
export interface CompaniesApiCompanyWaterfallLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyWaterfallLink
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyWaterfallLink
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiCompanyWaterfallLink
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompaniesCreate operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompaniesCreateRequest
 */
export interface CompaniesApiInternalCompaniesCreateRequest {
    /**
     * 
     * @type {InternalCompanyCreateRequestContract}
     * @memberof CompaniesApiInternalCompaniesCreate
     */
    readonly internalCompanyCreateRequestContract: InternalCompanyCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompaniesIndex operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompaniesIndexRequest
 */
export interface CompaniesApiInternalCompaniesIndexRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiInternalCompaniesIndex
     */
    readonly dataRequested?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiInternalCompaniesIndex
     */
    readonly covered?: boolean

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompaniesReset operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompaniesResetRequest
 */
export interface CompaniesApiInternalCompaniesResetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalCompaniesReset
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesReset
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesReset
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompaniesShow operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompaniesShowRequest
 */
export interface CompaniesApiInternalCompaniesShowRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalCompaniesShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompaniesShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompanyApplyStockSplit operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompanyApplyStockSplitRequest
 */
export interface CompaniesApiInternalCompanyApplyStockSplitRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalCompanyApplyStockSplit
     */
    readonly id: number

    /**
     * 
     * @type {InternalStockSplitRequestContract}
     * @memberof CompaniesApiInternalCompanyApplyStockSplit
     */
    readonly internalStockSplitRequestContract: InternalStockSplitRequestContract

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompanyApplyStockSplit
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompanyApplyStockSplit
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalCompanyPreDelete operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalCompanyPreDeleteRequest
 */
export interface CompaniesApiInternalCompanyPreDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalCompanyPreDelete
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompanyPreDelete
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalCompanyPreDelete
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalDeleteCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalDeleteCompanyRequest
 */
export interface CompaniesApiInternalDeleteCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalDeleteCompany
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalDeleteCompany
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalDeleteCompany
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalInvestmentPreDelete operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalInvestmentPreDeleteRequest
 */
export interface CompaniesApiInternalInvestmentPreDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalInvestmentPreDelete
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalInvestmentPreDelete
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalInvestmentPreDelete
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalUpdateCompanyRequest
 */
export interface CompaniesApiInternalUpdateCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiInternalUpdateCompany
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUpdateCompany
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUpdateCompany
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalCompanyUpdateRequestContract}
     * @memberof CompaniesApiInternalUpdateCompany
     */
    readonly internalCompanyUpdateRequestContract?: InternalCompanyUpdateRequestContract
}

/**
 * Request parameters for internalUploadFirmographics operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalUploadFirmographicsRequest
 */
export interface CompaniesApiInternalUploadFirmographicsRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadFirmographics
     */
    readonly file: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadFirmographics
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadFirmographics
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesApiInternalUploadFirmographics
     */
    readonly includeField?: Array<string>
}

/**
 * Request parameters for internalUploadSacraData operation in CompaniesApi.
 * @export
 * @interface CompaniesApiInternalUploadSacraDataRequest
 */
export interface CompaniesApiInternalUploadSacraDataRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadSacraData
     */
    readonly file: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadSacraData
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiInternalUploadSacraData
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesApiInternalUploadSacraData
     */
    readonly includeField?: Array<string>
}

/**
 * Request parameters for publicCompaniesShow operation in CompaniesApi.
 * @export
 * @interface CompaniesApiPublicCompaniesShowRequest
 */
export interface CompaniesApiPublicCompaniesShowRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesApiPublicCompaniesShow
     */
    readonly id: number
}

/**
 * Request parameters for secondMarketCompanyShow operation in CompaniesApi.
 * @export
 * @interface CompaniesApiSecondMarketCompanyShowRequest
 */
export interface CompaniesApiSecondMarketCompanyShowRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiSecondMarketCompanyShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiSecondMarketCompanyShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiSecondMarketCompanyShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @summary get filing dates for a specific company
     * @param {CompaniesApiCompaniesFilingDatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesFilingDates(requestParameters: CompaniesApiCompaniesFilingDatesRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesFilingDates(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all companies that meet the specific filters
     * @param {CompaniesApiCompaniesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesIndex(requestParameters: CompaniesApiCompaniesIndexRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesIndex(requestParameters.sectors, requestParameters.minLatestInvestmentSize, requestParameters.maxLatestInvestmentSize, requestParameters.minLatestInvestmentDate, requestParameters.maxLatestInvestmentDate, requestParameters.minTotalFunding, requestParameters.maxTotalFunding, requestParameters.minLatestValuation, requestParameters.maxLatestValuation, requestParameters.coveredOnGt, requestParameters.covered, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get latest PPS(price per share) and Valuation details for a specific company
     * @param {CompaniesApiCompaniesLatestStockClassDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesLatestStockClassDetails(requestParameters: CompaniesApiCompaniesLatestStockClassDetailsRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesLatestStockClassDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates data_requested flag on record
     * @param {CompaniesApiCompaniesRequestDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesRequestData(requestParameters: CompaniesApiCompaniesRequestDataRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesRequestData(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a specific company
     * @param {CompaniesApiCompaniesShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesShow(requestParameters: CompaniesApiCompaniesShowRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get cap table information for a company
     * @param {CompaniesApiCompanyCapTableMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyCapTableMetadata(requestParameters: CompaniesApiCompanyCapTableMetadataRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyCapTableMetadata(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns financial activity
     * @param {CompaniesApiCompanyFinancialActivityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyFinancialActivity(requestParameters: CompaniesApiCompanyFinancialActivityRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyFinancialActivity(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns implied values
     * @param {CompaniesApiCompanyImpliedValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyImpliedValues(requestParameters: CompaniesApiCompanyImpliedValuesRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyImpliedValues(requestParameters.id, requestParameters.valuation, requestParameters.pricePerShare, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get basic information for a specific company
     * @param {CompaniesApiCompanyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyProfile(requestParameters: CompaniesApiCompanyProfileRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyProfile(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns link to Waterfall file
     * @param {CompaniesApiCompanyWaterfallLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyWaterfallLink(requestParameters: CompaniesApiCompanyWaterfallLinkRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyWaterfallLink(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a company
     * @param {CompaniesApiInternalCompaniesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompaniesCreate(requestParameters: CompaniesApiInternalCompaniesCreateRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompaniesCreate(requestParameters.internalCompanyCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a list of companies
     * @param {CompaniesApiInternalCompaniesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompaniesIndex(requestParameters: CompaniesApiInternalCompaniesIndexRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompaniesIndex(requestParameters.dataRequested, requestParameters.covered, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete associated records for a company
     * @param {CompaniesApiInternalCompaniesResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompaniesReset(requestParameters: CompaniesApiInternalCompaniesResetRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompaniesReset(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a specific company
     * @param {CompaniesApiInternalCompaniesShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompaniesShow(requestParameters: CompaniesApiInternalCompaniesShowRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompaniesShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary apply stock split
     * @param {CompaniesApiInternalCompanyApplyStockSplitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompanyApplyStockSplit(requestParameters: CompaniesApiInternalCompanyApplyStockSplitRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompanyApplyStockSplit(requestParameters.id, requestParameters.internalStockSplitRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request associated records for a company before deletion
     * @param {CompaniesApiInternalCompanyPreDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalCompanyPreDelete(requestParameters: CompaniesApiInternalCompanyPreDeleteRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalCompanyPreDelete(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a company
     * @param {CompaniesApiInternalDeleteCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalDeleteCompany(requestParameters: CompaniesApiInternalDeleteCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalDeleteCompany(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request associated records for a company before deletion
     * @param {CompaniesApiInternalInvestmentPreDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalInvestmentPreDelete(requestParameters: CompaniesApiInternalInvestmentPreDeleteRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalInvestmentPreDelete(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a company
     * @param {CompaniesApiInternalUpdateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalUpdateCompany(requestParameters: CompaniesApiInternalUpdateCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalUpdateCompany(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalCompanyUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary bulk update firmographics
     * @param {CompaniesApiInternalUploadFirmographicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalUploadFirmographics(requestParameters: CompaniesApiInternalUploadFirmographicsRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalUploadFirmographics(requestParameters.file, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary bulk update Sacra Data
     * @param {CompaniesApiInternalUploadSacraDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public internalUploadSacraData(requestParameters: CompaniesApiInternalUploadSacraDataRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).internalUploadSacraData(requestParameters.file, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a specific company
     * @param {CompaniesApiPublicCompaniesShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public publicCompaniesShow(requestParameters: CompaniesApiPublicCompaniesShowRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).publicCompaniesShow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get company for SecondMarket
     * @param {CompaniesApiSecondMarketCompanyShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public secondMarketCompanyShow(requestParameters: CompaniesApiSecondMarketCompanyShowRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).secondMarketCompanyShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompaniesFilingDatesErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesFilingDatesErrorCodes = [
];

export type CompaniesIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesIndexErrorCodes = [
];

export type CompaniesLatestStockClassDetailsErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesLatestStockClassDetailsErrorCodes = [
    404,
];

export type CompaniesRequestDataErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesRequestDataErrorCodes = [
];

export type CompaniesShowErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesShowErrorCodes = [
    404,
];

export type CompanyCapTableMetadataErrorTypes =

    | {
        status: 404;
        data: CapTableCompanyMetadata;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyCapTableMetadataErrorCodes = [
    404,
];

export type CompanyFinancialActivityErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyFinancialActivityErrorCodes = [
];

export type CompanyImpliedValuesErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyImpliedValuesErrorCodes = [
    404,
];

export type CompanyProfileErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyProfileErrorCodes = [
];

export type CompanyWaterfallLinkErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyWaterfallLinkErrorCodes = [
    401,
];

export type InternalCompaniesCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompaniesCreateErrorCodes = [
    401,
];

export type InternalCompaniesIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompaniesIndexErrorCodes = [
    401,
];

export type InternalCompaniesResetErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompaniesResetErrorCodes = [
    401,
    404,
];

export type InternalCompaniesShowErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompaniesShowErrorCodes = [
    401,
    404,
];

export type InternalCompanyApplyStockSplitErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyApplyStockSplitErrorCodes = [
    401,
    404,
];

export type InternalCompanyPreDeleteErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalCompanyPreDeleteErrorCodes = [
    401,
    404,
];

export type InternalDeleteCompanyErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteCompanyErrorCodes = [
    401,
];

export type InternalInvestmentPreDeleteErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalInvestmentPreDeleteErrorCodes = [
    401,
    404,
];

export type InternalUpdateCompanyErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateCompanyErrorCodes = [
    404,
];

export type InternalUploadFirmographicsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUploadFirmographicsErrorCodes = [
];

export type InternalUploadSacraDataErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUploadSacraDataErrorCodes = [
];

export type PublicCompaniesShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PublicCompaniesShowErrorCodes = [
];

export type SecondMarketCompanyShowErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondMarketCompanyShowErrorCodes = [
    401,
];


